import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { subcomponent } from '@helpers';
import styles from './styles.module.scss';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';

const EmployeeCard = ({ position, name, image, bio }) => {
    const [displayModal, setDisplayModal] = useState(false);

    const handleModalClose = e => {
        e.preventDefault();
        setDisplayModal(false);
    };

    return (
        <Fragment>
            <div className={styles.employeeCard} onClick={() => setDisplayModal(true)}>
                <div className={styles.heading}>
                    <p>{position}</p>
                    <h3>{name}</h3>
                    <img src="/images/squiggle.svg" alt="a squiggle line" />
                </div>
                <div className={styles.imageContainer}>
                    <Img fluid={image?.childImageSharp?.fluid} objectFit={"center 20%"} alt={`photo of ${name}`} />
                </div>
                <div className={styles.bioContainer}>
                    <span className={styles.readBio}>READ BIO</span>
                </div>
            </div>
            <Modal show={displayModal} onClose={e => handleModalClose(e)}>
                <div className={styles.modalContainer}>
                    <h3 className={styles.modalHeader}>{name}</h3>
                    <button className={styles.modalClose} onClick={e => handleModalClose(e)}>
                        <FontAwesomeIcon icon={['fas', 'times']} />
                    </button>
                    <div className={styles.modalImageContainer}>
                        <Img fluid={image?.childImageSharp?.fluid} alt={`photo of ${name}`} />
                    </div>
                    <Divider margin={3} />
                    <div className={styles.modalBody}>
                        {!!bio && (
                            <div
                                // className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: bio,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default EmployeeCard;
