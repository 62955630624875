import React, { Fragment } from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Button from '@components/Button';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import StoryTellingSlider from '@components/StoryTellingSlider';
import EmployeeCard from '@components/EmployeeCard';
import ContactCTA from '@components/ContactCTA';
import FreshApproach from "@components/FreshApproach";

const About = ({
    data: {
        page: {
            frontmatter: { title, description, employees, sliderItems, values, heroGraphic },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`About`} />
            <div className={styles.aboutWrapper}>
                <Header
                    title={title}
                    description={description}
                    addButton
                    layout="about"
                    backgroundTransparent
                />

                <div className={styles.heroCardsContainer}>
                    <h3>Our Team</h3>
                    <Divider margin={4} />
                    <Grid>
                        <Row center="xs">
                            {employees?.slice(0,2)?.map(({ name, position, description, image }, index) => (
                                <Col xl={4} lg={4} md={5} sm={6} xs={12} key={name}>
                                    <EmployeeCard
                                        name={name}
                                        position={position.toUpperCase()}
                                        image={image}
                                        bio={description}
                                    />
                                    <Divider xsMargin={4} smMargin={4} />
                                </Col>
                            ))}
                        </Row>
                        <Row center="xs">
                            {employees?.slice(2)?.map(({ name, position, description, image }, index) => (
                                <Col xl={4} lg={4} md={5} sm={6} xs={12} key={name}>
                                    <EmployeeCard
                                        name={name}
                                        position={position.toUpperCase()}
                                        image={image}
                                        bio={description}
                                    />
                                     <Divider xsMargin={4} smMargin={4} />
                                </Col>
                            ))}
                        </Row>
                    </Grid>
                </div>

                <Divider margin={4} />
                <Divider margin={4} />

                <FreshApproach values={values} />

                <Divider margin={4} />
                <Divider margin={4} />

                <div className={styles.downloadButton}>
                    <a href={'/ly-manifesto-latest.pdf'} target="_blank">
                        <Button text="View our Manifesto PDF" />
                    </a>
                </div>

                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />

                <StoryTellingSlider storyTelling={sliderItems} />

                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />

                <ContactCTA />
            </div>
        </Layout>
    );
};

export default About;

export const query = graphql`
    query aboutPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: about_title
                description: about_description
                heroGraphic: about_us_hero_graphic {
                    publicURL
                }
                employees: about_employees {
                    name: about_employees_employee_name
                    id: about_employees_employee_id
                    position: about_employees_employee_position
                    description: about_employees_employee_description
                    image: about_employees_employee_image {
                        childImageSharp {
                            fluid(maxWidth: 3000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                values: about_values {
                    title: about_values_value_title
                    text: about_values_value_text
                    image: about_values_value_image {
                        publicURL
                    }
                }
                sliderItems: about_from_here_items {
                    title: about_from_here_items_item_title
                    description: about_from_here_items_item_description
                    images: about_from_here_items_item_images {
                        childImageSharp {
                            fluid(maxWidth: 750) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    overviewImage: about_from_here_items_item_overview_image {
                        childImageSharp {
                            fluid(maxWidth: 3000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
